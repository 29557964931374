import { TokenContext } from "context/TokenContext";
import { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as S from "./style";
import { metaLoginHandler } from "utils/requests/other";

const MetaLogin = () => {
  const navigate = useNavigate();
  const { decodedToken } = useContext(TokenContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const socialId = searchParams.get("socialId") ?? "";

  const patchMetaToken = async () => {
    await metaLoginHandler({
      socialId: socialId,
    }).then((res) => {
      if (!res.message) {
        navigate("/dashboard");
      }
    });
  };

  useEffect(() => {
    if (socialId) {
      patchMetaToken();
    }
  }, [socialId]);

  return (
    <>
      <S.Background container>
        <S.Loader />
      </S.Background>
    </>
  );
};
export default MetaLogin;
